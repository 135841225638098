<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="10">
          Eligibility
        </v-col>
        <v-col cols="2">
          <v-btn class="btn btn-primary text-white" depressed @click="addEligibility()">Add Eligibility</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <table class="table">
            <thead>
            <th>Eligibility Type</th>
            <th>Score</th>
            <th>Detail</th>
            <th>Obtained Score</th>
            <th>Action</th>
            </thead>
            <tbody v-if="eligibilities.length>0">
            <tr v-for="(eligibility, index) in eligibilities" :key="index">
              <td>{{ eligibility.eligibility_type_text }}</td>
              <td>{{ eligibility.total }}</td>
              <td>
                <span v-if="eligibility.eligibility_type == 'exam'" class="badge badge-primary mx-1">
                    {{ eligibility.exam_type?eligibility.exam_type.toUpperCase():null }}
                </span>
                <span v-if="eligibility.eligibility_type == 'exam' && eligibility.exam_type=='ielts'"
                      class="badge badge-primary">
                  Each Band:
                    {{ eligibility.each_band_score }}
                </span>
              </td>
              <td>
                <p
                    class="font-size-base font-weight-bold">
                  <b>Writing:</b>
                  <span class="badge badge-info mx-1">{{ eligibility.writing }}</span>
                  <b>Reading</b>:
                  <span class="badge badge-info mx-1">{{ eligibility.reading }}</span>
                  <b>Reading</b>:
                  <span class="badge badge-info mx-1">{{ eligibility.reading }}</span>
                  <b>Reading</b>:
                  <span class="badge badge-info mx-1">{{ eligibility.reading }}</span>
                  <b>OverAll</b>:
                  <span class="badge badge-info mx-1">{{ eligibility.overall }}</span>
                </p>
              </td>
              <td>
                <a @click="editEligibility(eligibility)"><i class="fas fa-edit mr-2"></i></a>
                <a @click="deleteEligibility(eligibility.id)"><i class="fas fa-trash"></i></a>
              </td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="50" class="text-center font-weight-bold font-size-lg">No data found</td>
            </tr>
            </tbody>

          </table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dialog" width="1200" persistent>
      <v-card>
        <v-card-title>
          Create Eligibility
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="3">
              <v-select
                  :error="$v.eligibility.eligibility_type.$error"
                  :items="eligibility_types"
                  v-model="eligibility.eligibility_type"
                  dense item-text="title"
                  item-value="value" label="Eligibility Type"
                  outlined
                  prepend-inner-icon="fas fa-university"
              ></v-select>
              <span class="text-danger" v-if="$v.eligibility.eligibility_type.$error">Eligibility type required</span>
            </v-col>
            <v-col md="3" v-if="eligibility.eligibility_type == 'exam'">
              <v-select
                  :error="$v.eligibility.exam_type.$error"
                  v-model="eligibility.exam_type"
                  :items="exam_types"
                  @change="isCheckExamTypeExist"
                  dense item-text="name" item-value="id" label="Exam Type"
                  outlined
                  prepend-inner-icon="fas fa-university"
              ></v-select>
              <span class="text-danger"
                    v-if="eligibility.eligibility_type == 'exam' && $v.eligibility.exam_type.$error">Exam
              type required</span>
              <span class="text-danger"
                    v-if="isCheckExist">It is already taken.</span>
            </v-col>

            <v-col md="3" v-if="eligibility.eligibility_type == 'exam'">
              <v-text-field dense label="Required Score"
                            outlined
                            type="number"
                            v-model="eligibility.total"
                            :error="eligibility.eligibility_type == 'exam' && $v.eligibility.total.$error"
              ></v-text-field>
              <span class="text-danger"
                    v-if="eligibility.eligibility_type == 'exam' && $v.eligibility.total.$error">Overall score is required</span>
            </v-col>

            <v-col md="3" v-if="eligibility.eligibility_type == 'exam' && eligibility.exam_type=='ielts'">
              <v-text-field dense label="Each Band Score"
                            outlined
                            type="number"
                            v-model="eligibility.each_band_score"
                            :error="eligibility.exam_type=='ielts' && $v.eligibility.each_band_score.$error"
              ></v-text-field>
              <span class="text-danger"
                    v-if="eligibility.eligibility_type == 'exam'
                    && eligibility.exam_type=='ielts'
                     && $v.eligibility.each_band_score.$error">Each band score is required</span>
            </v-col>

            <v-col md="3" v-if="eligibility.eligibility_type == 'academics'">
              <v-text-field dense label="Minimum Score Required"
                            outlined
                            type="number"
                            v-model="eligibility.total"
                            :error="eligibility.eligibility_type == 'academics' && $v.eligibility.total.$error"
              ></v-text-field>
              <span class="text-danger"
                    v-if="eligibility.eligibility_type == 'academics' && $v.eligibility.total.$error">Min academic score is required</span>
            </v-col>

            <v-col cols="12" v-if="eligibility.eligibility_type == 'exam'">
              <h4>Eligibility Score</h4>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                  dense
                  outlined
                  @input="calculateOverAll"
                  v-if="eligibility.eligibility_type == 'exam'"
                  v-model="eligibility.reading"
                  type="number"
              >
                <template v-slot:label>
                  Reading <span class="text-danger">*</span>
                </template>
              </v-text-field>
<!--              <span class="text-danger" v-if="$v.eligibility.reading.$error">This Field is required</span>-->
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  dense
                  outlined
                  @input="calculateOverAll"
                  v-if="eligibility.eligibility_type == 'exam'"
                  v-model="eligibility.writing"
                  type="number"
              >
                <template v-slot:label>
                  Writing <span class="text-danger">*</span>
                </template>
              </v-text-field>
<!--              <span class="text-danger" v-if="$v.eligibility.writing.$error">This Field is required</span>-->
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  dense
                  outlined
                  @input="calculateOverAll"
                  v-if="eligibility.eligibility_type == 'exam'"
                  v-model="eligibility.listening"
                  type="number"
              >
                <template v-slot:label>
                  Listening <span class="text-danger">*</span>
                </template>
              </v-text-field>
<!--              <span class="text-danger" v-if="$v.eligibility.listening.$error">This Field is required</span>-->
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  dense
                  outlined
                  @input="calculateOverAll"
                  v-if="eligibility.eligibility_type == 'exam'"
                  v-model="eligibility.speaking"
                  type="number"
              >
                <template v-slot:label>
                  Speaking <span class="text-danger">*</span>
                </template>
              </v-text-field>
<!--              <span class="text-danger" v-if="$v.eligibility.speaking.$error">This Field is required</span>-->
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                  dense
                  outlined
                  v-if="eligibility.eligibility_type == 'exam'"
                  v-model="eligibility.overall"
                  type="number"
              >
                <template v-slot:label>
                  OverAll <span class="text-danger">*</span>
                </template>
              </v-text-field>
<!--              <span class="text-danger" v-if="$v.eligibility.overall.$error">This Field is required</span>-->
            </v-col>

<!--            <v-col md="12" v-if="eligibility.eligibility_type">-->
<!--              <v-textarea v-model="eligibility.description" outlined dense></v-textarea>-->
<!--            </v-col>-->
            <!--        <v-col md="2">-->
            <!--          <div class="d-flex justify-content-start">-->

            <!--            <v-btn v-if="index == (eligibilities.length-1)" color="green" class=""    @click="addEligibility()">-->
            <!--              <i class="fas fa-plus text-white"></i>-->
            <!--            </v-btn>-->
            <!--              <v-btn v-if="index<eligibilities.length && eligibilities.length>1"   color="red" class=" ml-2"   @click="removeEligibility(index)">-->
            <!--                <i class="fas fa-trash text-white"></i>-->
            <!--              </v-btn>-->
            <!--          </div>-->
            <!--        </v-col>-->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              depressed
              color="mr-2" text dark
              @click="resetDialog"
          >Close
          </v-btn>
          <v-btn :loading="loading"
                 depressed
                 class="btn btn-primary"
                 medium
                 @click="createOrUpdate"
          >Save Eligibility
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import AcademicProgramEligibilityService
  from "@/services/academic/program/eligibility/AcademicProgramEligibilityService";
import {required} from "vuelidate/lib/validators";

const eligibilityService = new AcademicProgramEligibilityService();
export default {
  name: "Eligibility",
  props: ['program'],
  data() {
    return {
      dialog: false,
      loading: false,
      edit: false,
      isCheckExist: false,
      eligibilities: [],
      eligibility: {
        reading: 0,
        listening: 0,
        speaking: 0,
        writing: 0,
        overall: 0,
        eligibility_type: null,
        exam_type: null,
        each_band_score: null,
        total: null,
        description: null,
        min_score: null
      },
      eligibility_types: [
        {title: "Exam", value: 'exam'},
        {title: "Academics", value: 'academics'},
      ],
      exam_types: [
        {id: 'ielts', name: "IELTS",},
        {id: 'toefl', name: "TOEFL",},
        {id: 'pte', name: "PTE",},
        {id: 'gre', name: "GRE",}, {id: 'gmat', name: "GMAT",},
      ],

    }
  }, validations() {
    return {
      eligibility: this.eligibility_rule
    }
  },
  computed: {
    eligibility_rule() {
      let rule = {
        eligibility_type: {required},
        total: {required},
      }
      if (this.eligibility.eligibility_type == "exam") {
        rule.exam_type = {required}

      }
      if (this.eligibility.eligibility_type == "exam" && this.eligibility.exam_type == "ielts")
        rule.each_band_score = {required}
      return rule;
    },
    calculateOverAll() {
      if(this.eligibility.eligibility_type == 'exam') {
        let reading = this.eligibility.reading ? parseFloat(this.eligibility.reading) : 0;
        let listening = this.eligibility.listening ? parseFloat(this.eligibility.listening) : 0;
        let speaking = this.eligibility.speaking ? parseFloat(this.eligibility.speaking) : 0;
        let writing = this.eligibility.writing ? parseFloat(this.eligibility.writing) : 0;
        let total = reading +  listening + speaking + writing;
        let overAll = parseFloat(parseFloat(total)/ 4).toFixed(1);
        if(!isNaN(total) && !isNaN(overAll)) {
          this.eligibility.overall = overAll;
        }
      }
    },
  }, mounted() {
    this.getEligibilities()
  },
  methods: {
    isCheckExamTypeExist() {
      if(this.eligibility.exam_type) {
        eligibilityService.isCheckExamTypeExist(this.program.id, this.eligibility.exam_type).then(response => {
          this.isCheckExist = response.data.status;
        });
      }
    },
    addEligibility() {
      this.dialog = true;
    },
    resetDialog() {
      this.dialog = false;
      this.edit = false
      this.isCheckExist = false
      this.eligibility = {
        exam_type: null,
        each_band_score: null,
        total: null,
        reading: 0,
        listening: 0,
        speaking: 0,
        writing: 0,
        overall: 0,
        description: null,
      }
      this.$v.eligibility.$reset();
    },
    createOrUpdate() {
      this.$v.eligibility.$touch();
      if (this.$v.eligibility.$error) {
        setTimeout(() => {
          this.$v.eligibility.$reset();
        }, 3000)
      } else {
        if (this.edit) {
          this.update()
        } else {
          this.create();
        }
      }
    },
    create() {
      this.loading = true
      eligibilityService.create(this.program.id, this.eligibility).then(response => {
        this.$snotify.success("eligibility created successfully")
      }).catch(error => {
        this.$snotify.error("Something went wrong. Please try again later")
      }).finally(() => {
        this.loading = false
        this.resetDialog();
        this.getEligibilities()
      })
    },
    update() {
      this.loading = true
      eligibilityService.update(this.program.id, this.eligibility.id, this.eligibility).then(response => {
        this.$snotify.success("eligibility update successfully")
      }).catch(error => {
        this.$snotify.error("Something went wrong. Please try again later")
      }).finally(() => {
        this.loading = false
        this.resetDialog();
        this.getEligibilities()
      })
    },
    getEligibilities() {
      eligibilityService.paginate(this.program.id).then(response => {
        this.eligibilities = response.data.data
      })
    },
    deleteEligibility(id) {
      this.loading = true
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            eligibilityService.delete(this.program.id, id).then(response => {
              this.$snotify.success("eligibility deleted successfully")
            }).catch(error => {
              this.$snotify.error("Something went wrong. Please try again later")
            }).finally(() => {
              this.loading = false
              this.resetDialog();
              this.getEligibilities()
            })
          }
        }
      });
    },
    editEligibility(eligibility) {
      this.eligibility = eligibility
      this.eligibility.reading = eligibility.reading ? eligibility.reading : 0;
      this.eligibility.listening = eligibility.listening ? eligibility.listening : 0;
      this.eligibility.speaking = eligibility.speaking ? eligibility.speaking : 0;
      this.eligibility.writing = eligibility.writing ? eligibility.writing : 0;
      this.dialog = true
      this.edit = true
    }
  }
}
</script>

<style scoped>

</style>