<template>
  <v-card flat>
    <v-card-text>
      <v-col cols="12">
        <v-spacer></v-spacer>
        <v-btn @click="__add()" class="mt-4 btn btn-primary" style="color: #fff">
          <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
          Add New
        </v-btn>
      </v-col>
      <div class="table-responsive" style="table-layout: fixed">
        <v-skeleton-loader
            type="table-thead"
            v-if="isLoading">
        </v-skeleton-loader>

        <v-skeleton-loader
            v-if="isLoading"
            type="table-row-divider@25">
        </v-skeleton-loader>
        <table class="table">
          <thead>
          <tr class="px-3">
            <th><strong>Session Name</strong></th>
            <th><strong>Session Start Date</strong></th>
            <th><strong>Session End Date</strong></th>
            <th><strong>Is Recursion</strong></th>
            <th><strong>Status</strong></th>
            <th class="pr-3 text-center"><strong>Action</strong></th>
          </tr>
          </thead>
          <tbody>
          <template>
            <tr v-for="item in sortedItems()" :key="item.id">


              <td class="px-2">
                <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ item.title }}</a>
              </td>
              <td class="px-2">
                <template v-if="item.formatted_start_date">
                  <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{
                      item.formatted_start_date
                    }}</span>
                </template>
                <template v-else>-</template>
              </td>
              <td class="px-2">
                <template v-if="item.formatted_end_date">
                  <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{
                      item.formatted_end_date
                    }}</span>
                </template>
                <template v-else>-</template>
              </td>
              <td>
                <span class="text-xl-body-2 badge" :class="item.is_recursion ? 'badge-primary' : 'badge-danger'">{{item.is_recursion ? 'Yes' : 'No'}}</span>
              </td>
              <td>
                <span class="text-uppercase text-xl-body-2 badge badge-primary">{{replaceUnderScore(item.status)}}</span>
              </td>

              <td class="pr-0 text-center">
                <template>
                  <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                  >
                    <template v-slot:button-content>
                      <i class="ki ki-bold-more-hor"></i>
                    </template>
                    <!--begin::Navigation-->
                    <div class="navi navi-hover min-w-md-250Approved Application Remarkspx">

                      <b-dropdown-text tag="div" class="navi-item">
                        <a class="navi-link" @click="editSession(item)">
                                                    <span class="navi-icon">
                                                        <v-icon color="orange darken-2">fas fa-edit</v-icon>
                                                    </span>
                          <span class="navi-text">Edit Session</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-text tag="div" class="navi-item">
                        <a class="navi-link" @click="deleteSession(item.id)">
                                                    <span class="navi-icon">
                                                        <v-icon color="red">fas fa-trash</v-icon>
                                                    </span>
                          <span class="navi-text">Delete</span>
                        </a>
                      </b-dropdown-text>
                    </div>
                    <!--end::Navigation-->
                  </b-dropdown>
                </template>
              </td>
            </tr>
            <tr v-if="sortedItems().length == 0">
              <td colspan="5" class="text-center">
                <strong>No items added</strong>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <b-pagination
            v-if="sortedItems.length > 0"
            class="pull-right mt-7"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number :disabled="isLoading"
        ></b-pagination>
      </div>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-card-title>
          Manage Sessions
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="4">
              <v-text-field
                  outlined
                  dense
                  label="Title"
                  v-model="academic_session.title"
                  :error="$v.academic_session.title.$error"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.academic_session.title.$error">Session is required</span>
            </v-col>
            <v-col md="4">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="menu"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="formattedStartDate"
                      prepend-inner-icon="mdi-calendar"
                      label="Start date"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      Start date<span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    @input="menu = false"
                    v-model="academic_session.start_date"
                    type="month"
                    :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                ></v-date-picker>
              </v-menu>
              <!--                                    <span academic-class="text-danger" v-if="$v.program.founded_date.$error">Start  Date is required</span>-->
            </v-col>

            <v-col md="4">
              <v-menu
                  :close-on-content-click="false"
                  min-width="auto"
                  offset-y
                  ref="menu"
                  transition="scale-transition"
                  v-model="menu1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      :value="formattedEndDate"
                      prepend-inner-icon="mdi-calendar"
                      label="Start date"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      outlined
                      dense
                  >
                    <template v-slot:label>
                      End date<span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                    no-title
                    @input="menu1 = false"
                    :min="academic_session.start_date"
                    v-model="academic_session.end_date"
                    type="month"
                ></v-date-picker>
              </v-menu>
              <!--                                    <span academic-class="text-danger" v-if="$v.program.founded_date.$error">End Date Date is required</span>-->
            </v-col>
            <v-col md="4">
              <v-select
                  :items="session_statuses"
                  dense
                  flat
                  label="Status"
                  v-model="academic_session.status"
                  outlined
                  item-text="title"
                  item-value="value"
              >
              </v-select>
            </v-col>
            <v-col md="4">
              <v-switch
                  label="Is Recursion"
                  v-model="academic_session.is_recursion"
              >
              </v-switch>
            </v-col>
            <v-col cols="12 text-right">
              <v-btn
                  :loading="loading"
                  @click="createOrUpdate"
                  class="btn btn-primary text-white"
              >Save
              </v-btn>
              <v-btn @click="resetForm()"
                     class="ml-4 btn btn-standard text-white"
              >Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import AcademicProgramSessionService
  from "@/services/academic/program/session/AcademicProgramSessionService";
import {required} from "vuelidate/lib/validators";

const academicProgramSessionService = new AcademicProgramSessionService();
export default {
  name: "Sessions",
  props: ['program'],
  validations: {
    academic_session: {
      title: {required}
    }
  },
  data() {
    return {
      session_statuses: [
        {title: 'Open Now', value: 'open'},
        {title: 'Likely Open', value: 'scheduled_to_open'},
        {title: 'Closed', value: 'closed'},
      ],
      enrollments: [],
      academic_sessions: [],
      total: null,
      menu: false,
      menu1: false,
      perPage: null,
      page: null,
      academic_session: {
        academic_program_id: null,
        start_date: null,
        end_date: null,
        status: null,
        title: null
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      sort: {
        key: '',
        isAsc: false
      },
      loading: false,
      isLoading: false,
      activePicker: false,
      dialog: false,
    }
  }, mounted() {
    this.__getAcademicSessions();
  },
  computed: {
    formattedStartDate() {
      return this.academic_session.start_date ? this.$moment(this.academic_session.start_date).format('MMM YYYY') : '';
    },
    formattedEndDate() {
      return this.academic_session.end_date ? this.$moment(this.academic_session.end_date).format('MMM YYYY') : '';
    }
  },
  methods: {
    __getAcademicSessions() {
      this.loading = true;
      academicProgramSessionService
          .paginate(this.program.id)
          .then(response => {
            this.academic_sessions = response.data.data;
            this.loading = false;
            this.sortedItems()
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          }).finally(this.loading = false)
    },
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortedItems() {
      const list = this.academic_sessions.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },
    __add() {
      this.dialog = true;
      this.academic_session.academic_program_id = this.program.id;
    },
    createOrUpdate() {
      this.$v.academic_session.$touch()
      if (this.$v.academic_session.$error) {
        setTimeout(() => {
          this.$v.academic_session.$reset()
        }, 3000)
        return
      }
      if (!this.edit) this._create();
      else this.update();
    },
    _create: function () {
      this.loading = true;
      academicProgramSessionService
          .create(this.program.id, this.academic_session)
          .then(response => {
            this.$snotify.success("Session created successfully");
            this.resetForm();
            this.errors = [];
            this.loading = false;
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(this.loading = false)
    },
    update: function () {
      this.loading = true;
      academicProgramSessionService
          .update(this.program.id, this.academic_session.id, this.academic_session)
          .then(response => {
            this.$snotify.success("Session updated successfully");
            this.resetForm();
            this.errors = [];
            this.loading = false;
          })
          .catch(error => {
            this.errors = error.errors;
          }).finally(this.loading = false)
    },
    resetForm() {
      this.academic_session = {
        attribute_id: null,
        institution_id: null,
        type: 'description',
        description: null
      };
      this.dialog = false
      this.edit = false
      this.__getAcademicSessions();
      this.$v.$reset();
    },
    editSession(item) {
      this.academic_session = item
      this.dialog = true
      this.edit = true
    },
    deleteSession(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            academicProgramSessionService
                .delete(this.program.id, id)
                .then(response => {
                  this.$snotify.success("Session deleted successfully");
                  this.resetForm();
                  this.errors = [];
                  this.loading = false;
                })
                .catch(error => {
                  this.loading = false;
                  this.errors = error.errors;
                }).finally(this.loading = false)
          }
        }
      });
    },
    replaceUnderScore(status){
      return status.replaceAll("_", " ");
    }
  },

}
</script>

<style scoped>

</style>
