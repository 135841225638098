import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class AcademicProgramEligibilityService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/academic-program';
    }

    paginate(academicProgramId, data = {}, index = null) {
        let url = `${this.#api}/${academicProgramId}/eligibility`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }


    show(academicProgramId, id) {
        let url = `${this.#api}/${academicProgramId}/eligibility/${id}`
        return apiService.get(url)
    }

    create(academicProgramId, data) {
        let url = `${this.#api}/${academicProgramId}/eligibility`
        return apiService.post(url, data)
    }

    update(academicProgramId, id, data) {
        let url = `${this.#api}/${academicProgramId}/eligibility/${id}`
        return apiService.put(url, data)
    }

    delete(academicProgramId, id) {
        let url = `${this.#api}/${academicProgramId}/eligibility/${id}`
        return apiService.delete(url)
    }

    isCheckExamTypeExist(academicProgramId, examType) {
        let url = `${this.#api}/${academicProgramId}/eligibility/${examType}/check-exist`
        return apiService.get(url)
    }
}