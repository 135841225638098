<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card" style="height: auto">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ program_id ? 'Update' : 'Add' }} Program
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \
                  Program
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" >
            <v-tabs vertical>
              <v-tab>
                <v-icon left>
                  mdi-account
                </v-icon>
                General
              </v-tab>
              <v-tab v-if="program_id && checkIsAccessible('feature', 'show')">
                <v-icon left>
                  fas fa-shapes
                </v-icon>
                Features
              </v-tab>
              <v-tab v-if="program_id && checkIsAccessible('location', 'show')">
                <v-icon left>
                  fas fa-map-marker
                </v-icon>
                Locations and session
              </v-tab>
<!--              <v-tab v-if="program_id && checkIsAccessible('academic-program-session', 'show')">-->
<!--                <v-icon left>-->
<!--                  fas fa-business-time-->
<!--                </v-icon>-->
<!--                Sessions-->
<!--              </v-tab>-->
              <v-tab v-if="program_id && checkIsAccessible('academic-program-eligibility', 'show')">
                <v-icon left>
                  mdi-access-point
                </v-icon>
                Eligibility
              </v-tab>
              <v-tab v-if="program_id && checkIsAccessible('media', 'show')">
                <v-icon left>
                  mdi-access-point
                </v-icon>
                Medias
              </v-tab>
              <v-tab v-if="program_id && checkIsAccessible('required-document', 'show')">
                <v-icon left>
                  mdi-file-document-box
                </v-icon>
                Required documents
              </v-tab>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col class="text-left" cols="12">
                        <h4>General information</h4>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field :error="$v.program.title.$error" dense
                                      label="Program name"
                                      outlined
                                      v-model="program.title">
                          <template v-slot:label>
                            Program name <span class="text-danger">*</span>
                          </template>
                        </v-text-field>
                        <span class="text-danger" v-if="$v.program.title.$error">This information is required</span>
                      </v-col>
<!--                      <v-col cols="12" md="6">-->
<!--                        <v-text-field dense-->
<!--                                      label="Short name"-->
<!--                                      outlined-->
<!--                                      v-model="program.short_name">-->
<!--                          <template v-slot:label>-->
<!--                            Short name-->
<!--                          </template>-->
<!--                        </v-text-field>-->
<!--                        <span class="text-danger" v-if="$v.program.short_name.$error">This information is required</span>-->

<!--                      </v-col>-->
                      <v-col cols="6">
                        <v-select
                            outlined
                            dense
                            :items="academic_program_categories"
                            item-text="title"
                            item-value="id"
                            multiple
                            v-model="program.academic_program_categories_ids"
                            :error="$v.program.academic_program_categories_ids.$error"
                            label="Program category"
                        >
                        </v-select>
                      </v-col>
<!--                      <v-col cols="6">-->
<!--                        <v-autocomplete-->
<!--                            :error="$v.program.session_id.$error"-->
<!--                            dense-->
<!--                            outlined-->
<!--                            v-model="program.session_id"-->
<!--                            :items="program_sessions"-->
<!--                            item-text="title"-->
<!--                            item-value="id"-->
<!--                            multiple-->
<!--                            chips-->
<!--                            small-chips-->
<!--                            deletable-chips-->
<!--                            clearable-->
<!--                            label="Academic sessions"-->
<!--                        >-->
<!--                          <template v-slot:label>-->
<!--                            Academic sessions-->
<!--                          </template>-->
<!--                        </v-autocomplete>-->
<!--&lt;!&ndash;                        <span class="text-danger" v-if="$v.program.program_sessions.$error">This information is required</span>&ndash;&gt;-->

<!--                      </v-col>-->

                      <v-col md="3">
                        <v-autocomplete
                            v-model="program.institution_id"
                            :loading="loading"
                            :items="institutions"
                            cache-items
                            dense item-text="title"
                            item-value="id"
                            outlined
                            hide-no-data
                            @change="changeInstitution()"
                            hide-details
                            prepend-inner-icon="fas fa-university"
                            label="Select institution"
                        ></v-autocomplete>
                      </v-col>
                      <v-col md="3">
                        <v-select :error="$v.program.education_level_id.$error"
                                  :items="education_levels"
                                  dense item-text="title" item-value="id" label="Education level"
                                  outlined
                                  prepend-inner-icon="fas fa-university"
                                  @change="changeEducationLevel()"
                                  return-object
                                  v-model="program.education_level_id"
                        ></v-select>
                        <span class="text-danger"
                              v-if="$v.program.education_level_id.$error">This information is required</span>
                      </v-col>
<!--                      <v-col md="3">-->
<!--                        <v-select-->
<!--                            :items="grading_schemes"-->
<!--                            v-model="program.grading_scheme_id"-->
<!--                            dense-->
<!--                            flat-->
<!--                            label="Grading scheme"-->
<!--                            item-text="title"-->
<!--                            item-value="id"-->
<!--                            outlined-->
<!--                        >-->
<!--                        </v-select>-->
<!--                        <span class="text-danger" v-if="$v.program.grading_scheme_id.$error">This information is required</span>-->
<!--                      </v-col>-->
<!--                      <v-col cols="12" md="3">-->
<!--                        <v-text-field-->
<!--                            outlined-->
<!--                            dense-->
<!--                            v-model="program.subject_code"-->
<!--                            label="Subject code"-->
<!--                        >-->
<!--                        </v-text-field>-->
<!--                      </v-col>-->
                      <v-col cols="12" md="3">
                        <v-autocomplete dense label="Program duration"
                                        :items="durations"
                                        item-text="name"
                                        item-value="value"
                                      outlined
                                      prepend-inner-icon="fas fa-clock "
                                      v-model="program.program_duration">
                        </v-autocomplete>
<!--                        <span class="text-danger" v-if="$v.program.program_duration.$error">This information is required</span>-->
                      </v-col>

                      <v-col cols="12" md="3">
                        <v-select
                            :items="['Years', 'Year(2 Semesters)', 'Year(3 Semesters)']"
                            v-model="program.duration_unit"
                            :error="$v.program.duration_unit.$error"
                            dense
                            flat
                            label="Program duration unit"
                            outlined
                        >
                        </v-select>
                        <span class="text-danger" v-if="$v.program.duration_unit.$error">This information is required</span>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                            outlined
                            dense
                            :items="study_modes"
                            item-text="name"
                            item-value="value"
                            v-model="program.study_mode"
                            label="Study mode"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="3">
                        <v-select
                            outlined
                            dense
                            :items="study_loads"
                            item-text="name"
                            item-value="value"
                            v-model="program.study_load"
                            label="Study load"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" md="3">
                        PGWP program
                        <v-switch :label="program.is_pgwp ? 'Yes' : 'No'" v-model="program.is_pgwp"></v-switch>
                      </v-col>

                      <v-col cols="12" md="3" v-if="program.is_pgwp">
                        <v-text-field
                            outlined
                            dense
                            v-model="program.cip_code"
                            :error="$v.program.cip_code.$error"
                            label="CIP code"
                        >
                          <template v-slot:label>
                            CIP code <span class="text-danger">*</span>
                          </template>
                        </v-text-field>
                        <span class="text-danger" v-if="$v.program.cip_code.$error">This information is required</span>

                      </v-col>

                      <v-col cols="12" md="3" v-if="country && country.title == 'Australia'">
                        <v-text-field
                            outlined
                            dense
                            v-model="program.cricos_code"
                            label="CRICOS code"
                        >
                        </v-text-field>
                      </v-col>
<!--                      <v-col cols="12" md="6">-->
<!--                        <v-switch label="Any country applicant can apply for this program?" v-model="program.available_for_all"></v-switch>-->
<!--                      </v-col>-->
                      <!--                      <pre>{{university}}</pre>-->
                      <v-col cols="12" md="12" v-if="!program.available_for_all">
                        <v-select
                            multiple
                            :items="all_countries"
                            dense
                            item-text="title"
                            item-value="id"
                            label="Select countries whose applicants can apply for this program"
                            outlined
                            prepend-inner-icon="fas fa-globe-americas"
                            v-model="program.available_except"
                            :error="!program.available_for_all && $v.program.available_except.$error"
                        >
                        </v-select>
                        <span class="text-danger"
                              v-if="!program.available_for_all && $v.program.available_except.$error">Please select not allowed for admission nationality</span>
                      </v-col>

                      <v-col cols="12">
                        <h3>Fees</h3>
                      </v-col>
<!--                      <v-col md="3">-->
<!--                        <v-text-field dense label="Application fee"-->
<!--                                      outlined-->
<!--                                      prepend-inner-icon="fas fa-money-bill-alt"-->
<!--                                      :suffix="country ? country.currency : ''"-->
<!--                                      type="number"-->
<!--                                      v-model="program.application_fee"></v-text-field>-->
<!--                        <span class="text-danger" v-if="$v.program.application_fee.$error">This information is required</span>-->
<!--                      </v-col>-->

<!--                      <v-col md="3">-->
<!--                        <v-text-field dense label="Total Tution Fee"-->
<!--                                      outlined-->
<!--                                      :error="$v.program.full_course_fee.$error"-->
<!--                                      prepend-inner-icon="fas fa-money-bill-alt"-->
<!--                                      :suffix="country ? country.currency : ''"-->
<!--                                      type="number"-->
<!--                                      v-model="program.full_course_fee"></v-text-field>-->
<!--                        <span class="text-danger" v-if="$v.program.full_course_fee.$error">Program Course Fee is required</span>-->

<!--                      </v-col>-->
                      <v-col md="3">
                        <v-text-field dense label="Annual/Semester tuition fee"
                                      outlined
                                      prepend-inner-icon="fas fa-money-bill-alt"
                                      :suffix="country ? country.currency : ''"
                                      type="number"
                                      v-model="program.tution_fee"></v-text-field>
<!--                        <span class="text-danger"-->
<!--                              v-if="$v.program.tution_fee.$error">This information is required</span>-->
                      </v-col>
                      <v-col md="3">
                        <v-select dense label="Fee period"
                                  outlined
                                  item-text="title"
                                  item-value="value"
                                  :items="tution_fee_frequencies"
                                  v-model="program.tution_fee_frequency"></v-select>
<!--                        <span class="text-danger" v-if="$v.program.tution_fee_frequency.$error">Annual/Semester Tuition Fee Frequency is required</span>-->
                      </v-col>
<!--                      <v-col md="3">-->
<!--                        <v-text-field-->
<!--                            label="Enrollment Fee"-->
<!--                            v-model="program.enrolment_fee"-->
<!--                            prepend-inner-icon="fas fa-money-bill-alt"-->
<!--                            :suffix="country ? country.currency : ''"-->
<!--                            type="number"-->
<!--                            dense-->
<!--                            outlined-->
<!--                        ></v-text-field>-->
<!--                      </v-col>-->

<!--                      <v-col md="3">-->
<!--                        <v-text-field dense label="Material Fee"-->
<!--                                      outlined-->
<!--                                      v-model="program.material_fee"-->
<!--                                      prepend-inner-icon="fas fa-money-bill-alt"-->
<!--                                      :suffix="country ? country.currency : ''"-->
<!--                                      type="number"-->

<!--                        ></v-text-field>-->
<!--                      </v-col>-->

                      <v-col md="3">
                        <v-text-field
                            v-model="program.currency_symbol"
                            label="Currency"
                            outlined
                            disabled
                            dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <label for>Description</label>
                        <ckeditor :config="editorConfig"
                                  v-model="program.description"></ckeditor>
                      </v-col>

                      <v-col cols="6">
                        <v-textarea
                            v-model="program.ielts_reqirement"
                            label="IELTS Requirement"
                            outlined
                            dense
                        ></v-textarea>
                      </v-col>

                      <v-col cols="6">
                        <v-textarea
                            v-model="program.academic_requirement"
                            label="Academic Requirement"
                            outlined
                            dense
                        ></v-textarea>
                        <span class="text-danger">You can write 160 letter</span>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                            v-model="program.required_documents"
                            :items="document_list"
                            item-value="id"
                            item-text="title"
                            label="Select document submission checklist"
                            :error="isChecklistEmpty"
                            clearable
                            small-chips
                            deletable-chips
                            multiple dense outlined>
                          <template v-slot:prepend-item>
                            <v-list-item ripple @mousedown.prevent @click="toggle">
                              <v-list-item-action>
                                <v-icon :color="program.required_documents.length > 0 ? 'indigo darken-4' : ''">
                                  {{ icon }}
                                </v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>
                                  Select All
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider class="mt-2"></v-divider>
                          </template>

                        </v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                          outlined
                          dense
                          :items="applicationIntakeStatuses"
                          v-model="program.application_intake_status"
                          item-text="name"
                          item-value="value"
                          label="Application intake status"
                          placeholder="Application intake status"
                        >
                        </v-select>
                      </v-col>


<!--                      <v-col cols="12" md="4">-->
<!--                        <v-text-field-->
<!--                            dense-->
<!--                            label="Keywords"-->
<!--                            outlined-->
<!--                            v-model="program.keywords"-->
<!--                        >-->

<!--                        </v-text-field>-->
<!--                      </v-col>-->

<!--                      <v-col cols="12" md="4">-->
<!--                        <v-text-field-->
<!--                            dense-->
<!--                            label="Seo Title"-->
<!--                            outlined-->
<!--                            v-model="program.seo_title"-->
<!--                        >-->
<!--                        </v-text-field>-->
<!--                      </v-col>-->

<!--                      <v-col cols="12" md="4">-->
<!--                        <v-text-field-->
<!--                            dense-->
<!--                            label="Seo Keyword"-->
<!--                            outlined-->
<!--                            v-model="program.seo_keyword"-->
<!--                        >-->
<!--                        </v-text-field>-->
<!--                      </v-col>-->
<!--                      <v-col cols="12" md="6">-->
<!--                        SEO Description-->
<!--                        <ckeditor-->
<!--                            :config="editorConfig1"-->
<!--                            v-model="program.seo_description"-->
<!--                        >-->
<!--                        </ckeditor>-->
<!--                      </v-col>-->

<!--                      <v-col cols="12" md="6">-->
<!--                        Social share description-->
<!--                        <ckeditor-->
<!--                            :config="editorConfig2"-->
<!--                            v-model="program.social_share_description"-->
<!--                        >-->
<!--                        </ckeditor>-->
<!--                      </v-col>-->


                      <v-col cols="12" md="3">
                       Featured program
                        <v-switch :label="program.is_featured ? 'Yes' : 'No'" v-model="program.is_featured"></v-switch>
                      </v-col>

                      <v-col cols="12" md="3" v-if="country && country.title == 'Canada'">
                        Co-op required
                        <v-switch @change="resetCoop" :label="program.is_co_op ? 'Yes' : 'No'" v-model="program.is_co_op"></v-switch>
                      </v-col>

                      <v-col cols="12" md="3">
                        Status
                        <v-switch
                            :label="program.is_active ? 'Active' : 'Inactive'"
                            v-model="program.is_active"
                        ></v-switch>
                      </v-col>

                      <v-col cols="12" v-if="program.is_co_op">
                        <v-select
                            v-model="program.coop_option"
                            :error="$v.program.coop_option.$error"
                            :items="coop_options"
                            item-text="name"
                            item-value="value"
                            outlined
                            dense
                        >
                          <template v-slot:label>
                            Co Op Option <span class="text-danger">*</span>
                          </template>
                        </v-select>
                        <span v-if="$v.program.coop_option.$error" class="text-danger">This Field is required</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12 text-right">
                        <v-btn
                            @click="cancel"
                            color="mr-2" text dark
                        >Cancel
                        </v-btn>
                        <v-btn
                            v-if="checkIsAccessible('academic-program', 'create') || checkIsAccessible('academic-program', 'edit')"
                            :loading="loading"
                            @click="createOrUpdate"
                            color="ml-4 btn btn-primary"
                        >{{ program_id ? "Update" : "Save" }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item v-if="checkIsAccessible('feature', 'show')">
                <features :program="program"></features>
              </v-tab-item>
              <v-tab-item v-if="checkIsAccessible('location', 'show')">
                <locations :institution_id="program.institution_id" :program="program"></locations>
              </v-tab-item>
<!--              <v-tab-item v-if="checkIsAccessible('academic-program-session', 'show')">-->
<!--                <sessions :program="program"></sessions>-->
<!--              </v-tab-item>-->
              <v-tab-item v-if="checkIsAccessible('academic-program-eligibility', 'show')">
                <eligibility :program="program"></eligibility>
              </v-tab-item>
              <v-tab-item v-if="checkIsAccessible('media', 'show')">
                <v-card flat>
                  <v-card-text>
                    <v-row class="mb-3">
                      <v-col class="text-left" cols="12">
                        <label for>Images</label>
                        <UploadImages @changed="handleImages"/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                          v-for="(media,index) in medias"
                          :key="index"
                          class="d-flex child-flex"
                          cols="2"
                      >
                        <v-hover>
                          <template v-slot:default="{ hover }">
                            <v-avatar size="128" tile>
                              <v-img :src="media.image_path['real']"
                                     :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`">
                                <template v-slot:placeholder>
                                  <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                  >
                                    <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                    ></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                              <v-fade-transition>
                                <v-overlay
                                    absolute
                                    color="#036358"
                                    v-if="hover"
                                >
                                  <v-btn @click="__removeImage(media)" fab small>
                                    <v-icon>
                                      fas fa-trash
                                    </v-icon>
                                  </v-btn>
                                </v-overlay>
                              </v-fade-transition>
                            </v-avatar>
                          </template>
                        </v-hover>

                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item v-if="checkIsAccessible('required-document', 'show')">
                <required-document :item="program"></required-document>
              </v-tab-item>
            </v-tabs>


          </div>
        </div>
      </div>
    </div>
    <v-dialog
        max-width="900"
        v-model="logoSelector"
    >
      <v-card>
        <v-card-title class="text-h5">
          Choose Logo
        </v-card-title>

        <v-card-text>
          Choose to change a Program logo and crop
          <v-row>
            <v-col cols="6">
              <cropper
                  :src="img"
                  :stencil-props="{ aspectRatio: 10/12 }"
                  @change="change"
                  class="cropper"
                  style="max-height: 300px;"
              ></cropper>
            </v-col>
            <v-col>
              <v-file-input
                  :rules="rules"
                  @change="changeImage"
                  accept="image/png, image/jpeg, image/bmp"
                  dense
                  label="Logo"
                  outlined
                  placeholder="Pick an avatar"
                  prepend-icon="mdi-camera"
                  v-model="logo_img"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              @click="logoSelector = false"
              color="green darken-1"
              text
          >
            cancel
          </v-btn>

          <v-btn
              @click="logoSelector = false"
              color="green darken-1"
              text
          >
            save
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>

import {required, requiredIf} from "vuelidate/lib/validators";
import {Cropper} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import UploadImages from "vue-upload-drop-images"
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import InstitutionTypesService from "@/services/academic/institution/type/InstitutionTypesService";
import RequiredDocumentService from "@/services/required-document/RequiredDocumentService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import MediaService from "@/services/academic/institution/media/MediaService";
import Features from "./sections/Features";
import Locations from "./sections/Locations";
import Sessions from "./sections/Sessions";
import Eligibility from "@/view/pages/view/academic/program/sections/Eligibility";
import AcademicProgramCategoryService from "@/services/academic/program/category/AcademicProgramCategoryService";
import GradingSchemeService from "@/services/grading-scheme/GradingSchemeService";
import CountryService from "@/services/country/CountryService";
import RequiredDocument from "./sections/RequiredDocument";
import AcademicProgramSessionService from "@/services/academic/program/session/AcademicProgramSessionService";

const academicProgramService = new AcademicProgramService();
const institutionTypesService = new InstitutionTypesService()
const requiredDocument = new RequiredDocumentService();
const educationLevelService = new EducationLevelService();
const institutionService = new InstitutionService();
const gradingSchemeService = new GradingSchemeService();
const country = new CountryService();
const mediaService = new MediaService();
const programCategoryService = new AcademicProgramCategoryService();
const programSession = new AcademicProgramSessionService();

export default {
  name: "CreateAndUpdate",
  components: {
    Cropper,
    VuePhoneNumberInput,
    UploadImages,
    Features,
    Locations,
    Sessions,
    Eligibility,
    RequiredDocument

  },
  data() {
    return {
      tution_fee_frequencies: [
        {"title": "Annual", "value": "annual"},
        {"title": "Semester", "value": "semester"},
      ],
      title: '',
      menu: false,
      loading: false,
      logoSelector: false,
      isChecklistEmpty: false,
      activePicker: null,
      country: null,
      date: null,
      select: null,
      institutions_types: [],
      education_levels: [],
      institutions: [],
      grading_schemes: [],
      academic_program_categories: [],
      program_sessions: [],
      eligibility_type: [
        {
          id: 1,
          name: "IELTS",

        },
        {
          id: 2,
          name: "TOFEL",

        },
        {
          id: 3,
          name: "PTE",

        },
        {
          id: 3,
          name: "GRE",

        },
      ],
      durations: [
        {
          value: "1",
          name: 1,
        },
        {
          value: "2",
          name: 2,
        },
        {
          value: "3",
          name: 3,
        },
        {
          value: "4",
          name: 4,
        },
      ],
      study_modes: [
        {
          value: 'on_campus',
          name: "On campus",

        },
        {
          value: "online",
          name: "Online",

        },
        {
          value: 'mixed',
          name: "Mixed",

        },
      ],
      study_loads: [
        {
          value: 'full_time',
          name: "Full time",

        },
        {
          value: "part_time",
          name: "Part time",

        },
        {
          value: 'mixed',
          name: "Mixed",

        },
      ],
      coop_options: [
        {
          value: 'co_op_required',
          name: "Co-op Required",
        },
        {
          value: "co_op_optional",
          name: "Co-op optional",
        },
        {
          value: 'field_placement_required',
          name: "Field Placement Required",
        },
      ],
      program: {
        enrollments: [],
        eligilities: [
          {
            eligibility_type: null,
            required_score: null,
            status: null,
          }
        ],
        title: null,
        short_name: null,
        institution_id: null,
        education_level_id: null,
        program_duration: null,
        duration_unit: null,
        application_fee: null,
        currency_symbol: null,
        full_course_fee: null,
        is_featured: false,
        is_active: true,
        grading_scheme_id: null,
        available_for_all: true,
        available_except: null,
        tution_fee: null,
        tution_fee_frequency: null,
        subject_code: '',
        study_mode: 'on_campus',
        study_load: 'full_time',
        ielts_reqirement: 'IELTS (eqv) 6.0, Each Band and Overall 6.0',
        academic_requirement: '',
        required_documents: [],
        cricos_code: '',
        is_co_op: '',
        coop_option: '',
        application_intake_status: ''
      },
      document_list: [],
      enrollment: {
        start_date: null,
        end_date: null,
        status: null,
      },
      eligibility: {
        eligibility_type: null,
        required_score: null,
        status: null,
      },
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      errors: [],
      img: 'https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg',
      logo_img: 'https://photos.applyboard.com/schools/000/000/067/logos/original/52357ee9e65d65c8b9d76f57e613d76c.png?1514655143',
      editorConfig: {
        versionCheck: false,
        toolbar: [
            [
                'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
            ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
      editorConfig1: null,
      editorConfig2: null,
      institutionDetail: {},
      files: [],
      medias: [],
      applicationIntakeStatuses:[
        {name: 'Open', value:'open'},
        {name: 'Closed', value:'closed'},
        {name: 'Waitlisted', value:'waitlisted'},
      ]
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  validations() {
    return {
      program: this.program_rule
    }
  },
  computed: {
    program_id() {
      return this.$route.params.program_id;
    },
    likesAllRequiredDocument() {
      return this.program.required_documents.length === this.document_list.length
    },
    likesSomeRequiredDocument() {
      return this.program.required_documents.length > 0 && !this.likesAllRequiredDocument
    },
    icon() {
      if (this.likesAllRequiredDocument) return 'mdi-close-box'
      if (this.likesSomeRequiredDocument) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    program_rule() {
      return {
        title: {required},
        academic_program_categories_ids: {required},
        institution_id: {required},
        education_level_id: {required},
        duration_unit: {required},
        cip_code: {
          required: requiredIf(function () {
            return this.program.is_pgwp;
          })
        },
        coop_option: {
          required: requiredIf(function () {
            return this.program.is_co_op;
          })
        },
        is_active: {required},
      }
    }
  },
  mounted() {
    this.__getAllInstitutions()
    this._getAllInstitutionsTypes()
    // this._getAllEducationLevel()
    this._getAllEducationLevel()
    this.addEligility();
    this.__getMedias();
    this.__detail();
    this.getAllCountryList();
    this.getAcademicProgramCategory();
    this.editorConfig1 = this.editorConfig
    this.editorConfig2 = this.editorConfig
  },
  methods: {
    resetCoop() {
      if(!this.program.is_co_op) {
        this.program.coop_option = null;
      }
    },

    getAcademicProgramCategory() {
      programCategoryService.all().then(response => {
        this.academic_program_categories = response.data.categories
      }).catch(error => {
        this.academic_program_categories = [];
      })
    },
    getAllCountryList() {
      country.getAllCountry().then(response => {
        this.all_countries = response.data.data
      })
    },
    getCountryDetails(countryId) {
      country.getById(countryId).then(response => {
        this.country = response.data.country;
        if(this.country && this.country.currency) {
          this.program.currency_symbol = this.country.currency;
        }
      })
    },
    changeEducationLevel() {
      let education_level = this.program.education_level_id;
      if(education_level) {
        this.program.education_level_id = education_level.id;
        this.program.education_level = education_level.slug;
        this.program.required_documents = [];
        if(this.program.education_level == 'graduate-certificate' || this.program.education_level == 'masters') {
          this.program.required_documents.push(59);
        } else  {
          this.program.required_documents.push(60);
        }
      }
    },
    getGradingScheme() {
      if(this.country){
        gradingSchemeService.getByProgramLevelCountry(this.program.education_level_id, this.country.id).then(response => {
          this.grading_schemes = response.data.grading_schemes
        }).catch(error => {
        })
      }

    },
    save(date) {
      this.$refs.menu.save(date)
    },
    addEnrollment() {
      this.program.enrollments.push(this.enrolment)
    },
    addEligility() {
      this.program.eligilities.push(this.eligibility)
    },
    convertToFormData() {
      // if (this.program.required_documents.length > 0) {
      //   this.program.program.required_documents = this.program.required_documents.toString();
      // } else {
      //   this.program.program.required_documents = [];
      // }
      let formData = new FormData();
      for (let key in this.program) {
        if (key === "file" && this.program[key] != null && this.program[key] != undefined) {
          formData.append('file', this.program[key]);
        } else if (key === "is_featured") {
          let is_featured = this.program[key] ? 1 : 0;
          formData.append('is_featured', is_featured);
        } else if (key === "is_pgwp") {
          let pgwp = this.program[key] ? 1 : 0;
          formData.append('is_pgwp', pgwp);
        } else {
          if (this.program[key]) {
            formData.append(key, this.program[key]);
          }
        }
      }
      return formData;
    },
    changeImage(e) {
      this.img = URL.createObjectURL(this.logo_img)
    },
    change({coordinates, canvas}) {
      console.log(coordinates, canvas)
    },
    __detail() {
      if (this.program_id != null || this.program_id != undefined) {
        academicProgramService
            .show(this.program_id)
            .then(response => {
              this.program = response.data.program;
              if (this.program.image_path != null) {
                this.imgurl = this.program.image_path.thumb;
              }
              this._getAllEducationLevel()
              this.changeInstitution();
              if(this.program.ielts_reqirement == null) {
                this.program.ielts_reqirement = 'IELTS (eqv) 6.0, Each Band and Overall 6.0';
              }
              if(this.program.required_documents.length == 0) {
                if(this.program.education_level == 'graduate-certificate' || this.program.education_level == 'masters') {
                  this.program.required_documents.push(59);
                  this.isChecklistEmpty = true;
                } else  {
                  this.program.required_documents.push(60);
                  this.isChecklistEmpty = true;
                }
              }
            }).catch(err => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },
    previewImage() {
      if (this.program.file != null) {
        this.imgurl = URL.createObjectURL(this.program.file)
      } else {
        this.imgurl = null;
      }
    },

    resetForm() {
      this.$v.$reset();
      this.university = {
        name: '',
        description: '',
        keywords: '',
        seo_title: '',
        seo_keyword: '',
        seo_description: '',
        social_share_image: '',
        social_share_description: '',
        is_featured: false,
        is_active: true,
      }
    },
    //get all Institutions
    __getAllInstitutions() {
      this.loading = true;
      institutionService
          .all()
          .then(response => {
            this.institutions = response.data.universities;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (this.program.academic_program_categories_ids && this.program.academic_program_categories_ids)
          this.program.academic_program_categories_ids = this.program.academic_program_categories_ids.join(",")
        this.loading = true;
        if (this.program_id == null || this.program_id == undefined) this._create();
        else this.update();
      }
    },
    cancel() {
      this.$tabs.close();
    },
    _create: function () {
      let formData = this.convertToFormData();
      academicProgramService
          .create(formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Program added");
            this.resetForm();
            this.$tabs.close().then(() => {
              this.$tabs.open({name: "program"});
            });

          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },
    update() {
      let formData = this.convertToFormData();
      academicProgramService
          .update(this.program.id, formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Program updated");
            this.resetForm();
            this.$tabs.close().then(() => {
              this.$tabs.open({name: "program"});
            });
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },

    //get all Institutions Type
    _getAllInstitutionsTypes() {
      this.isLoading = true;
      institutionTypesService
          .paginate()
          .then(response => {
            this.institutions_types = response.data;
            this.isLoading = false;
          })
          .catch((err) => {
          }).finally(() => {
        this.isLoading = false;
      });
    },
    _getAllEducationLevel() {
      this.isLoading = true;
      educationLevelService
          .all()
          .then(response => {
            this.education_levels = response.data.educationLevels;
            this.isLoading = false;
          })
          .catch((err) => {
          }).finally(() => {
        this.isLoading = false;
      });
    },
    changeInstitution() {
      this.findInstitutionDetail();
    },
    findInstitutionDetail() {

      if (this.program.institution_id) {
        institutionService
            .show(this.program.institution_id)
            .then(response => {
              this.institutionDetail = response.data.university;
              this.loading = false;
              this.getAllRequiredDocument(this.institutionDetail.country_id);
              this.getCountryDetails(this.institutionDetail.country_id);
              this.getGradingScheme(this.institutionDetail.country_id);
            })
            .catch((err) => {
              this.loading = false;
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    getAllRequiredDocument(countryId) {
      this.document_list = [];
      requiredDocument
          .getByCountry(countryId)
          .then(response => {
            this.document_list = response.data.documents;
          })
    },
    handleImages(files) {
      this.files = files
      this.__upload();
    },
    __removeImage(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            mediaService
                .delete(item.id)
                .then((response) => {
                  this.__getMedias()
                  this.$snotify.success("Image Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    __upload() {
      const formData = new FormData();
      // this.isBusy = true;
      this.files.forEach(file => {
        if (file) {
          formData.append("image[]", file);
          formData.append("academic_program_id", this.program.id);
        }
      });
      mediaService
          .create(formData)
          .then(res => {
            // this.isBusy = false;
            this.$snotify.success("File Uploaded");
            this.__getMedias();
          });
    },
    __getMedias() {

      mediaService
          .getProgramAddress(this.program_id)
          .then(res => {
            this.medias = res.data.medias;
            this.files = [];
          })
          .catch(error => {
          })
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllRequiredDocument) {
          this.program.required_documents = []
        } else {
          this.program.required_documents = this.document_list.slice()
        }
      })
    },

  },

}
</script>
<style>
@import 'https://emoji-css.afeld.me/emoji.css';
</style>
